import React from "react"
import Layout from '../components/layout'
import SEO  from '../components/SEO'
import ContactForm from '../components/contact/ContactForm'
import ServiceForm from '../components/contact/ServiceForm'
import HTML from "../components/HTML"
import ImageParagraph from "../components/image/ImageParagraphComponent"
import AddtlButtons from "../components/additionalButtons/AddtlButtonsComponent"
import FinanceWidget from "../components/finance/FinanceWidget"
import Directions from "../components/directions/Directions"
import FinanceForm from "../components/financeForm/FinanceForm"
import TradeInForm from "../components/tradeInForm/TradeInForm"

function Contact(props) {
  const { pageContext } = props
  const { pageContent, theme,dealerInfo,inventorymanagement,lead_url,site_id, headerTags } = pageContext
  const dealerHours = dealerInfo
  const dealerLoc = dealerInfo
  const document = typeof window !== 'undefined' && window.document


  let components = [
    <ImageParagraph  data={pageContent.ImageParagraphComponent}></ImageParagraph>,
    <ContactForm data={pageContent.ContactForm} dealer={dealerInfo} site_id={pageContext.site_id}
                 lead_url={pageContext.lead_url} car={null} pageSource='Contact' pageURL={document.URL}></ContactForm>,
    <AddtlButtons disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} >
    </AddtlButtons>,
    <FinanceWidget data={pageContent.FinanceWidget} leadURL={pageContext.lead_url}
     pageSource='FinanceApp' pageURL={document.URL}  site_id={pageContext.site_id}
     extraData={""}/>,
    <ServiceForm data={pageContent.ServiceForm} leadURL={pageContext.lead_url}
    pageSource='Service' pageURL={document.URL}  site_id={pageContext.site_id}
    extraData={""}></ServiceForm>,
    <FinanceForm data={pageContent.FinanceForm} leadURL={pageContext.lead_url} site_id={pageContext.site_id} pageSource='FinanceForm' pageURL={document.URL} />,
    <TradeInForm data={pageContent.TradeInForm} leadURL={pageContext.lead_url} site_id={pageContext.site_id} pageSource='TradeInForm' pageURL={document.URL} />,
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} tagString={headerTags}/>,
    <Directions key={"DirectionsHours"}
    data={{...pageContent.DirectionsHours, ...dealerInfo }}/>
  ]

  if(pageContent.hasOwnProperty("ArrayRawHTML")) {
    const { arrayRawHTML } = pageContent.ArrayRawHTML
    const rawComponents = arrayRawHTML.map(element => {  
        return <HTML data={{ 
            order: Number(element.order),
            visible: element.visible,
            rawHTML: element.rawHtml
        }} key={"HTML"} />
    })
    components.push(...rawComponents)
}

let visibleComponents = components.filter(component => {
  return component.props.data.visible === true
})

  let sortedComponents = visibleComponents.sort((a, b) =>
     parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible

  return (
    <Layout>
      { sortedComponents}
    </Layout>
  )
}

export default Contact
