import React, { Fragment } from 'react'

export default () => {

    function ApplicantInfo() {

        const dropDownValue = (e) => {
            document.getElementById('HomeStatus').value = e.target.value
        }

        return (
            <div className="input__container">
                <h2 style={{color:"black"}}>APPLY FOR FINANCE WITH CO-APPLICANT</h2>
                <div className="input__title">
                    <h2>APPLICANT INFO</h2>
                </div>

                <label>First Name*</label>
                <input  type="text" className="input__field" name="FirstName" required />

                <label>Middle Initial</label>
                <input  type="text" className="input__field" name="MiddleInitial" />

                <label>Last Name*</label>
                <input  type="text" className="input__field" name="LastName" required />

                <label>Date of Birth*</label>
                <input  type="date" className="input__field" name="DOB" required />

                {/* <label>Social Security Number*</label>
                <input  type="text" className="input__field" name="SSN" required />

                <label>Driver's License #*</label>
                <input  type="text" className="input__field" name="DLN" required />

                <label>Driver's License State*</label>
                <input  type="text" className="input__field" name="DLS" required /> */}

                <label>Email Address*</label>
                <input  type="text" className="input__field" name="Email" required />

                <label>Phone #*</label>
                <input  type="text" className="input__field" name="Phone" required />

                <label>Street Address*</label>
                <input  type="text" className="input__field" name="Address" required />

                <label>Address Line 2</label>
                <input  type="text" className="input__field" name="Address2" />

                <label>City*</label>
                <input  type="text" className="input__field" name="City" required />

                <label>State*</label>
                <input  type="text" className="input__field" name="State" required />

                <label>Zip Code*</label>
                <input  type="text" className="input__field" name="Zip" required />

                <label>Rent or Own?*</label>
                <select id="HomeStatus" name="HomeStatus" onChange={dropDownValue} className="input__field" required >
                    <option disabled selected hidden>Select Status</option>
                    <option value="Rent">Rent</option>
                    <option value="Own">Own</option>
                </select>

                <label>Date of Residency*</label>
                <input  type="date" className="input__field" name="Residency" required />

                <label>Monthly Rent/Mortgage*</label>
                <input  type="text" className="input__field" name="MortgageRent" required />
            </div>
        )
    }

    function ApplicantEmployment() {
        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>APPLICANT EMPLOYMENT INFO</h2>
                </div>

                <label>Employer Name*</label>
                <input  type="text" className="input__field" name="EmployerName" required />

                <label>Employer Phone #*</label>
                <input  type="text" className="input__field" name="EmployerPhone" required />

                <label>Employer Address*</label>
                <input  type="text" className="input__field" name="EmployerAddress" required />

                <label>Employer City*</label>
                <input  type="text" className="input__field" name="EmployerCity" required />

                <label>Employer State</label>
                <input  type="text" className="input__field" name="EmployerState" />

                <label>Employer Zip*</label>
                <input  type="number" className="input__field" name="EmployerZip" required />

                <label>Occupation*</label>
                <input  type="text" className="input__field" name="Occupation" required />

                <label>Job Title*</label>
                <input  type="text" className="input__field" name="JobTitle" required />

                <label>Employment Status*</label>
                <input  type="text" className="input__field" name="EmploymentStatus" required />

                <label>Date of Employment</label>
                <input  type="date" className="input__field" name="DOE" />

                <label>Gross Monthly Income*</label>
                <input  type="text" className="input__field" name="MonthlyIncome" required />

                <label>Additional Income Source</label>
                <input  type="text" className="input__field" name="AdditionalIncomeSource" />

                <label>Additional Income Amount</label>
                <input  type="text" className="input__field" name="AdditionalIncomeAmount" />
            </div>
        )
    }

    function CoApplicantInfo() {

        const dropDownValue = (e) => {
            document.getElementById('CoApHomeStatus').value = e.target.value
        }

        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>CO-APPLICANT INFO</h2>
                </div>

                <label>First Name*</label>
                <input  type="text" className="input__field" name="CoApFirstName" required />

                <label>Middle Initial</label>
                <input  type="text" className="input__field" name="CoApMiddleInitial" />

                <label>Last Name*</label>
                <input  type="text" className="input__field" name="CoApLastName" required />

                <label>Date of Birth*</label>
                <input  type="date" className="input__field" name="CoApDOB" required />

                {/* <label>Social Security Number*</label>
                <input  type="text" className="input__field" name="CoApSSN" required />

                <label>Driver's License #*</label>
                <input  type="text" className="input__field" name="CoApDLN" required />

                <label>Driver's License State*</label>
                <input  type="text" className="input__field" name="CoApDLS" required /> */}

                <label>Email Address*</label>
                <input  type="text" className="input__field" name="CoApEmail" required />

                <label>Phone #*</label>
                <input  type="text" className="input__field" name="CoApPhone" required />

                <label>Street Address*</label>
                <input  type="text" className="input__field" name="CoApAddress" required />

                <label>Address Line 2</label>
                <input  type="text" className="input__field" name="CoApAddress2" />

                <label>City*</label>
                <input  type="text" className="input__field" name="CoApCity" required />

                <label>State*</label>
                <input  type="text" className="input__field" name="CoApState" required />

                <label>Zip Code*</label>
                <input  type="text" className="input__field" name="CoApZip" required />

                <label>Rent or Own?*</label>
                <select id="CoApHomeStatus" name="CoApHomeStatus" onChange={dropDownValue} className="input__field" required >
                    <option disabled selected hidden>Select Status</option>
                    <option value="Rent">Rent</option>
                    <option value="Own">Own</option>
                </select>

                <label>Date of Residency*</label>
                <input  type="date" className="input__field" name="CoApResidency" required />

                <label>Monthly Rent/Mortgage*</label>
                <input  type="text" className="input__field" name="CoApMortgageRent" required />
            </div>
        )
    }

    function CoApplicantEmployment() {
        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>CO-APPLICANT EMPLOYMENT INFO</h2>
                </div>

                <label>Employer Name*</label>
                <input  type="text" className="input__field" name="CoApEmployerName" required />

                <label>Employer Phone #*</label>
                <input  type="text" className="input__field" name="CoApEmployerPhone" required />

                <label>Employer Address*</label>
                <input  type="text" className="input__field" name="CoApEmployerAddress" required />

                <label>Employer City*</label>
                <input  type="text" className="input__field" name="CoApEmployerCity" required />

                <label>Employer State</label>
                <input  type="text" className="input__field" name="CoEmployerState" />

                <label>Employer Zip*</label>
                <input  type="number" className="input__field" name="CoApEmployerZip" required />

                <label>Occupation*</label>
                <input  type="text" className="input__field" name="CoApOccupation" required />

                <label>Job Title*</label>
                <input  type="text" className="input__field" name="CoApJobTitle" required />

                <label>Employment Status*</label>
                <input  type="text" className="input__field" name="CoApEmploymentStatus" required />

                <label>Date of Employment</label>
                <input  type="date" className="input__field" name="CoApDOE" />

                <label>Gross Monthly Income*</label>
                <input  type="text" className="input__field" name="CoApMonthlyIncome" required />

                <label>Additional Income Source</label>
                <input  type="text" className="input__field" name="CoApAdditionalIncomeSource" />

                <label>Additional Income Amount</label>
                <input  type="text" className="input__field" name="CoApAdditionalIncomeAmount" />
            </div>
        )
    }

    function CoAppVehicleInfo() {

        const dropDownValue = (e) => {
            document.getElementById('CoApHomeStatus').value = e.target.value
        }

        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>TELL US ABOUT THE VEHICLE YOU WANT</h2>
                </div>

                <label>Purchase Type*</label>
                <select name="PurchaseType" onChange={dropDownValue} className="input__field" required >
                    <option disabled selected hidden>Select Purchase Type</option>
                    <option value="New Purchase">New Purchase</option>
                    <option value="New Lease">New Lease</option>
                    <option value="Pre-Owned Purchase">Pre-Owned Purchase</option>
                </select>

                <label>Year</label>
                <input  type="text" className="input__field" name="Year" />

                <label>Make</label>
                <input  type="text" className="input__field" name="Make" />

                <label>Model</label>
                <input  type="text" className="input__field" name="Model" />

                <label>Trim</label>
                <input  type="text" className="input__field" name="Trim" />

                <label>Total Loan Amount*</label>
                <input  type="text" className="input__field" name="LoanAmount" required />
            </div>
        )
    }

    function TradeInInfo() {
        return (
            <div className="input__container">
                <label>Trade-In?*</label>
                <div className="input__field radio">
                    <label><input type="radio" name="TradeIn" value="Yes" required /> Yes</label>
                    <label><input type="radio" name="TradeIn" value="No" required /> No</label>
                </div>

                <label>Trade-In Year</label>
                <input type="text" className="input__field" name="TradeInYear" />

                <label>Trade-In Make</label>
                <input type="text" className="input__field" name="TradeInMake" />

                <label>Trade-In Model</label>
                <input type="text" className="input__field" name="TradeInModel" />

                <label>Trade-In Mileage</label>
                <input type="text" className="input__field" name="TradeInMileage" />
            </div>
        )
    }


    return (
        <Fragment>
            <ApplicantInfo/>
            <ApplicantEmployment/>
            <CoApplicantInfo/>
            <CoApplicantEmployment/>
            <CoAppVehicleInfo/>
            <TradeInInfo/>
        </Fragment>
    )
}
